<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Shartnoma registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group">
            <v-combobox
              style="height: 44px"
              :error-messages="providerNameErrors"
              v-model="provider_name"
              :items="getAllCorCards"
              v-debounce:400ms="myFnn"
              item-value="id"
              dense
              item-text="full__name"
              label="Postavshik nomi"
              outlined
              @input="$v.provider_name.$touch()"
              @blur="$v.provider_name.$touch()"
            ></v-combobox>
          </div>

          <!-- <div class="col-4">
            {{typeOfContract}}
            <v-select
              :error-messages="typeOfContractErrors"
              v-model="typeOfContract"
              item-text="state"
              outlined
              item-value="abbr"
              :items="items"
              label="Shartnoma turi"
              @input="$v.typeOfContract.$touch()"
              @blur="$v.typeOfContract.$touch()"
            ></v-select>
          </div> -->
          <div class="input__wrapper">
            <div class="col-3 pl-0">
              <v-text-field
                :error-messages="contractNumberErrors"
                v-model="contractNumber"
                label="Shartnoma nomeri"
                outlined
                dense
                @input="$v.contractNumber.$touch()"
                @blur="$v.contractNumber.$touch()"
              ></v-text-field>
            </div>
            <div class="col-3">
              <v-text-field
                :error-messages="amountErrors"
                v-model="amount"
                label="Summa"
                outlined
                dense
                v-currency="currencyOptions"
                @keypress="isNumberKey"
                @input="$v.amount.$touch()"
                @blur="$v.amount.$touch()"
              ></v-text-field>
            </div>
            <div class="col-3">
              <!-- <label>Shartnoma sanasi</label>
            <b-form-input
              :error-messages="contractNumberErrors"
              v-model="dateOFContract"
              id="`type-date`"
              type="date"
              @input="$v.contractNumber.$touch()"
              @blur="$v.contractNumber.$touch()"
            ></b-form-input> -->
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateOFContract"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="dateOFContractErrors"
                    outlined
                    :value="dateOFContract | dateFilter"
                    label="Shartnoma boshlang'ich sanasi"
                    append-icon="event"
                    readonly
                    @input="$v.dateOFContract.$touch()"
                    @blur="$v.dateOFContract.$touch()"
                    v-bind="attrs"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateOFContract" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="menu = false"
                    >Bekor qilish</v-btn
                  >
                  <v-btn
                    small
                    color="success"
                    @click="$refs.menu.save(dateOFContract)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="col-3 pr-0">
              <!-- <label>Shartnoma tugash sanasi</label>
            <b-form-input
              v-model="endOfContract"
              id="`type-date`"
              type="date"
            ></b-form-input> -->
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="endOfContract"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="endOfContractErrors"
                    outlined
                    label="Shartnoma tugash sanasi"
                    append-icon="event"
                    :value="endOfContract | dateFilter"
                    readonly
                    dense
                    @input="$v.endOfContract.$touch()"
                    @blur="$v.endOfContract.$touch()"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endOfContract" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="menu1 = false"
                    >Bekor qilish</v-btn
                  >
                  <v-btn
                    small
                    color="success"
                    @click="$refs.menu1.save(endOfContract)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newProviderLoading"
              >
                <i v-if="newProviderLoading" class="el-icon-loading"></i>
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import { isNumberKey, numberWithSpaces, currencyOptions } from '@/utils'

export default {
  data() {
    return {
      newProviderLoading: false,
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      provider_name: '',
      typeOfContract: '',
      dateOFContract: '',
      contractNumber: '',
      endOfContract: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false,
      amount: null
    }
  },
  validations: {
    provider_name: {
      required
    },
    amount: {
      required
    },
    // typeOfContract: {
    //   required
    // },
    dateOFContract: {
      required
    },
    contractNumber: {
      required
    },
    endOfContract: {
      required
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllProvidersContract')
    // this.$store.dispatch("getAllProviders");
  },
  computed: {
    currencyOptions,
    // options() {
    //   return {
    //     locale: 'en',
    //     currency: [null],
    //     valueAsInteger: false,
    //     distractionFree: true,
    //     precision: 0,
    //     autoDecimalMode: true,
    //     valueRange: { min: 0 },
    //     allowNegative: false
    //   }
    // },
    providerNameErrors() {
      const errors = []
      if (!this.$v.provider_name.$dirty) return errors

      !this.$v.provider_name.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    // typeOfContractErrors() {
    //   const errors = [];
    //   if (!this.$v.typeOfContract.$dirty) return errors;
    //   !this.$v.typeOfContract.required &&
    //     errors.push("To'ldirilishi shart bo'lgan qator");
    //   return errors;
    // },
    dateOFContractErrors() {
      const errors = []
      if (!this.$v.dateOFContract.$dirty) return errors

      !this.$v.dateOFContract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contractNumberErrors() {
      const errors = []
      if (!this.$v.contractNumber.$dirty) return errors

      !this.$v.contractNumber.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    amountErrors() {
      const errors = []
      if (!this.$v.amount.$dirty) return errors

      if (!this.$v.amount.required)
        errors.push("To'ldirilishi shart bo'lgan qator")

      return errors
    },
    endOfContractErrors() {
      const errors = []
      if (!this.$v.endOfContract.$dirty) return errors

      !this.$v.endOfContract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    getAllCorCards() {
      // const data = this.$store.state.requests.allProvidersContract;
      // data.forEach(element => {
      //   if (element.provider_inn == null) {
      //     element.provider_inn = "kiritilmagan";
      //   }
      //   element.provider_name =
      //     element.provider_name + " (Inn: " + element.provider_inn + ")";
      // });
      // return this.$store.state.requests.allProvidersContract.results;
      return this.$store.getters.providerSearchResult
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Shartnoma registratsiyasi' }
    ])
  },
  methods: {
    numberWithSpaces,
    isNumberKey,

    myFnn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('providerSearch', data)
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        const data = {
          // contract_type: this.typeOfContract,
          end_date: this.endOfContract,
          start_date: this.dateOFContract,
          contract_number: this.contractNumber,
          client: this.provider_name.id,
          amount: parseFloat(this.amount.replace(/,/g, ''))
        }
        this.newProviderLoading = true
        this.$store
          .dispatch('createProviderContract', data)
          .then(() => {
            this.newProviderLoading = false
            this.endOfContract = ''
            this.dateOFContract = ''
            this.contractNumber = ''
            this.provider_name = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newProviderLoading = false
            console.error(err)
          })
      }
      // this.typeOfContract = "";
    }
  }
}
</script>

<style scoped>
.col-4 {
  padding-left: 0;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
